import "core-js/stable";
import "regenerator-runtime/runtime";
import { Collapse } from 'bootstrap';

import "./js/config.js";

// Load in vendor scripts (jquery loaded by Webpack directly);
import "bootstrap";

// Lazysizes
import 'lazysizes';
import 'lazysizes/plugins/native-loading/ls.native-loading';


// Load in styles
import "./scss/styles.scss";

import validationService from './js/validationService';
import addressFinder from './js/addressFinder';

validationService();

// todo : move this to load if needed only
const addressFinders = document.querySelectorAll('.js-find-address');

if (addressFinders.length) {
    addressFinders.forEach((x) => {
        addressFinder(x);
    })
}

// Conditional dynamic imports

// Webpack will store those modules in separate JS files and
// load them on demand, keeping payload down.


// Load in custom JS modules required on initial page load

if (document.querySelector('body.site-layout') != null) {
    import('./js/scrolling');
}

if (document.querySelector('#checkout-time-remaining') != null) {
    import('./js/checkout-time-remaining');
}

if (document.querySelector('.emergency-message') != null) {
    import('./js/emergency-message');
}


// Load in custom JS modules on initial page load and when DOM is updated

const addDataJsParsed = (elements, tag = '') => {
    elements.forEach(x => {
        x.setAttribute(`data-js-parsed--${tag}`, '');
    });
}

const jsModuleCheck = () => {

    let testQuery = null;

    // smart-bootstrap-tabs
    testQuery = document.querySelector('.nav[role="tablist"]:not([data-js-parsed--smart-bootstrap-tabs])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.nav[role="tablist"]:not([data-js-parsed--smart-bootstrap-tabs])');
        addDataJsParsed(thisDomQuery, "smart-bootstrap-tabs");
        import('./js/smart-bootstrap-tabs').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // smooth-scroll-link
    testQuery = document.querySelector('a.smooth-scroll-link:not([data-js-parsed--smooth-scroll-link])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('a.smooth-scroll-link:not([data-js-parsed--smooth-scroll-link])');
        addDataJsParsed(thisDomQuery, "smooth-scroll-link");
        import('./js/smooth-scroll-link').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // non-lazyload img-load-watcher
    testQuery = document.querySelector('img.img-load-watcher:not([data-js-parsed--img-load-watcher])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('img.img-load-watcher:not([data-js-parsed--img-load-watcher])');
        addDataJsParsed(thisDomQuery, "img-load-watcher");
        import('./js/img-load-watcher').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // image-loader-group
    testQuery = document.querySelector('.image-loader-group:not([data-js-parsed--image-loader-group])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.image-loader-group:not([data-js-parsed--image-loader-group])');
        addDataJsParsed(thisDomQuery, "image-loader-group");
        import('./js/image-loader-group').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // hunt-watch
    testQuery = document.querySelector('.hunt-watch:not([data-js-parsed--hunt-watch])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.hunt-watch:not([data-js-parsed--hunt-watch])');
        addDataJsParsed(thisDomQuery, "hunt-watch");
        import('./js/hunt-watch').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // height matching
    testQuery = document.querySelector('*[data-hm-group]:not([data-js-parsed--height-match])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('*[data-hm-group]:not([data-js-parsed--height-match])');
        addDataJsParsed(thisDomQuery, "height-match");
        import('./js/height-match').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // lightboxes (PhotoSwipe v5 - galleries and single images)
    testQuery = document.querySelector('.lightbox-image:not([data-js-parsed--lightbox]), .image-gallery:not([data-js-parsed--lightbox])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.lightbox-image:not([data-js-parsed--lightbox]), .image-gallery:not([data-js-parsed--lightbox])');
        addDataJsParsed(thisDomQuery, "lightbox");
        import('./modules/lightboxes-galleries/lightboxes.js').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // responsive nav bar
    testQuery = document.querySelector('.responsive-nav-bar:not([data-js-parsed--responsive-nav-bar])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.responsive-nav-bar:not([data-js-parsed--responsive-nav-bar])');
        addDataJsParsed(thisDomQuery, "responsive-nav-bar");
        import('./modules/responsive-nav-bar/responsive-nav-bar').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // responsive table
    testQuery = document.querySelector('.responsive-table:not([data-js-parsed--responsive-table])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.responsive-table:not([data-js-parsed--responsive-table])');
        addDataJsParsed(thisDomQuery, "responsive-table");
        import('./modules/responsive-table/responsive-table');
    }

    // product-listing--sortable
    testQuery = document.querySelector('.product-listing--sortable:not([data-js-parsed--product-listing--sortable])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.product-listing--sortable:not([data-js-parsed--product-listing--sortable])');
        addDataJsParsed(thisDomQuery, "product-listing--sortable");
        import('./js/product-listing--sortable').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // product-gallery
    testQuery = document.querySelector('.product-gallery:not([data-js-parsed--product-gallery])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.product-gallery:not([data-js-parsed--product-gallery])');
        addDataJsParsed(thisDomQuery, "product-gallery");
        import('./js/product-gallery').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // width-watcher
    testQuery = document.querySelector('*[data-width-watcher]:not([data-js-parsed--width-watcher])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('*[data-width-watcher]:not([data-js-parsed--width-watcher])');
        addDataJsParsed(thisDomQuery, "width-watcher");
        import('./js/width-watcher').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // scroll indicator
    testQuery = document.querySelector('.scroll-indicator:not([data-js-parsed--scroll-indicator])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.scroll-indicator:not([data-js-parsed--scroll-indicator])');
        addDataJsParsed(thisDomQuery, "scroll-indicator");
        import('./js/scroll-indicator').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // tripadvisor icon shiv
    testQuery = document.querySelector('.fa-tripadvisor:not([data-js-parsed--fa-tripadvisor])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.fa-tripadvisor:not([data-js-parsed--fa-tripadvisor])');
        addDataJsParsed(thisDomQuery, "fa-tripadvisor");
        import('./js/tripadvisor-icon-shiv').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // tiktok icon shiv
    if (document.querySelector('.fa-tiktok') != null) {
        import('./js/tiktok-icon-shiv').then((js) => {
            js.createFrom(document.querySelectorAll('.fa-tiktok'));
        });
    }

    // form number control
    testQuery = document.querySelector('.form-number-control:not(.readonly):not([data-js-parsed--form-number-control])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.form-number-control:not(.readonly):not([data-js-parsed--form-number-control])');
        addDataJsParsed(thisDomQuery, "form-number-control");
        import('./js/form-number-control').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // visitors-quantity-selector-validation
    testQuery = document.querySelector('.visitors-quantity-selector--visitor-type[data-validation-required]:not([data-js-parsed--visitors-quantity-selector-validation])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.visitors-quantity-selector--visitor-type[data-validation-required]:not([data-js-parsed--visitors-quantity-selector-validation])');
        addDataJsParsed(thisDomQuery, "visitors-quantity-selector-validation");
        import('./js/visitors-quantity-selector-validation').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // form-check enable target
    testQuery = document.querySelector('input[data-form-check-enable-target]:not([data-js-parsed--form-check-enable-target])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('input[data-form-check-enable-target]:not([data-js-parsed--form-check-enable-target])');
        addDataJsParsed(thisDomQuery, "form-check-enable-target");
        import('./js/form-check-enable-target').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // radio-collapse-control
    testQuery = document.querySelector('.radio-collapse-control:not([data-js-parsed--radio-collapse-control])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.radio-collapse-control:not([data-js-parsed--radio-collapse-control])');
        addDataJsParsed(thisDomQuery, "radio-collapse-control");
        import('./js/radio-collapse-control').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // radiobutton-sync-to-tab
    testQuery = document.querySelector('.radiobutton-sync-to-tab:not([data-js-parsed--radiobutton-sync-to-tab])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.radiobutton-sync-to-tab:not([data-js-parsed--radiobutton-sync-to-tab])');
        addDataJsParsed(thisDomQuery, "radiobutton-sync-to-tab");
        import('./js/radiobutton-sync-to-tab').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // select-radio-on-focus
    testQuery = document.querySelector('.select-radio-on-focus:not([data-js-parsed--select-radio-on-focus])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.select-radio-on-focus:not([data-js-parsed--select-radio-on-focus])');
        addDataJsParsed(thisDomQuery, "select-radio-on-focus");
        import('./js/select-radio-on-focus').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // voucher-delivery-date
    testQuery = document.querySelector('input[data-voucher-delivery-date]:not([data-js-parsed--voucher-delivery-date])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('input[data-voucher-delivery-date]:not([data-js-parsed--voucher-delivery-date])');
        addDataJsParsed(thisDomQuery, "voucher-delivery-date");
        import('./js/voucher-delivery-date').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // membership-details-group
    testQuery = document.querySelector('.membership-details-group:not([data-js-parsed--membership-details-group])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.membership-details-group:not([data-js-parsed--membership-details-group])');
        addDataJsParsed(thisDomQuery, "membership-details-group");

        function onValidated() {
            const form = document.getElementById('membership-details-form');
            const details = form.querySelectorAll('.membership-details-group--item');

            const allValid = Array.from(details).every(function (detail) {
                if (!detail.classList.contains('editable')) {
                    return true;
                }
                return detail.classList.contains('validated');
            });

            if (allValid) {
                document.querySelector('.membership-details-group--continue-btn').disabled = false;
            }
            else {
                document.querySelector('.membership-details-group--continue-btn').disabled = true;
            }
        }

        function validateEmail(email) {
            return email.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        };

        function validateMemberDetails(details) {
            const address = details.obj.el.querySelector('#manual-address-entry');

            if (address) {
                const collapse = Collapse.getOrCreateInstance(address, { toggle: false });

                collapse.show();
            }

            const inputs = details.obj.el.querySelectorAll('input, select');

            const allValid = Array.from(inputs).every(function (input) {
                if (input.type === 'email') {
                    return validateEmail(input.value)
                }

                const valid = input.value !== '';

                return valid;
            });

            if (allValid) {
                return true;
            }

            const form = document.getElementById('membership-details-form');

            if (window.validationService.isValid(form)) {
                return true;
            }

            window.validationService.validateForm(form);

            return false;
        }

        if (testQuery.dataset.live === 'True') {
            import('./js/membership-details-group').then((js) => {
                js.createFrom(thisDomQuery, validateMemberDetails, onValidated);
            });
        } else {
            import('./js/membership-details-group').then((js) => {
                js.createFrom(thisDomQuery);
            });
        }
    }

    // custom-date-picker
    testQuery = document.querySelector('input[data-custom-date-picker]:not([data-js-parsed--custom-date-picker])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('input[data-custom-date-picker]:not([data-js-parsed--custom-date-picker])');
        addDataJsParsed(thisDomQuery, "custom-date-picker");
        import('./js/custom-date-picker').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // input-char-limit
    testQuery = document.querySelector('.input-char-limit:not([data-js-parsed--input-char-limit])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.input-char-limit:not([data-js-parsed--input-char-limit])');
        addDataJsParsed(thisDomQuery, "input-char-limit");
        import('./js/input-char-limit').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // input-digits-only
    testQuery = document.querySelector('input[data-digits-only]:not([data-js-parsed--digits-only])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('input[data-digits-only]:not([data-js-parsed--digits-only])');
        addDataJsParsed(thisDomQuery, "digits-only");
        import('./js/input-digits-only').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // checkout extras (modals)
    testQuery = document.querySelector('.checkout--extras-listing--item[data-extras-product-id]:not([data-js-parsed--checkout-extras])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.checkout--extras-listing--item[data-extras-product-id]:not([data-js-parsed--checkout-extras])');
        addDataJsParsed(thisDomQuery, "checkout-extras");
        import('./js/checkout-extras').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

};


//// create a mutation observer to watch for dynamically added HTML

let checkRequested = false, checkTimeout = null;

// run a check on page load
jsModuleCheck();

// Select the node that will be observed for mutations
const targetNode = document.querySelector('main');
if (targetNode != null) {
    // Options for the observer (which mutations to observe)
    const config = { attributes: false, childList: true, subtree: true };
    // Callback function to execute when mutations are observed
    const callback = function (mutationsList) {
        // Use traditional 'for loops' for IE 11
        let checkNeeded = false;
        for (const mutation of mutationsList) {
            if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                checkNeeded = true;
            }
        }
        if (checkNeeded && !checkRequested) {
            checkRequested = true;
            clearTimeout(checkTimeout);
            checkTimeout = setTimeout(() => {
                jsModuleCheck();
                checkRequested = false;
            }, 500);
        }
    };
    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);
    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);
}

const payButton = document.querySelector('.js-pay-button');

if (payButton) {
    payButton.addEventListener('click', (e) => {
        e.preventDefault();

        const paymentForm = document.querySelector('[data-vendr-payment-form]');

        if (paymentForm) {
            paymentForm.submit();
        }
    });
}


const calendarBookingElement = document.querySelector('.js-calendar-booking');
if (calendarBookingElement) {
    import('./js/calendarBookingApi').then(({ default: CalendarBookingApi }) => {
        new CalendarBookingApi(calendarBookingElement);
    });
}

const withoutCalendarBookingElement = document.querySelector('.js-without-calendar-booking');
if (withoutCalendarBookingElement) {
    import('./js/calendarBookingApi').then(({ default: CalendarBookingApi }) => {
        new CalendarBookingApi(withoutCalendarBookingElement);
    });
}

const animalAdoptionElement = document.querySelector('.js-animal-adoption');
if (animalAdoptionElement) {
    import('./js/animalAdoptionApi').then(({ default: AnimalAdoptionApi }) => {
        new AnimalAdoptionApi(animalAdoptionElement);
    });
}

const parkAnimalAdoptionElement = document.querySelector('.js-park-animal-adoption');
if (parkAnimalAdoptionElement) {
    import('./js/parkAnimalAdoptionApi').then(({ default: ParkAnimalAdoptionApi }) => {
        new ParkAnimalAdoptionApi(parkAnimalAdoptionElement);
    });
}

const giftVoucherForm = document.getElementById('gift-voucher-form');
if (giftVoucherForm) {
    import('./js/giftVouchers').then(({ default: giftVouchers }) => {
        giftVouchers(giftVoucherForm);
    });
}


const orderProcessing = document.querySelector('.js-order-processing');

if (orderProcessing) {
    setInterval(() => {
        fetch(`/Umbraco/Surface/OrderStatus/Get/?id=${orderProcessing.dataset.id}`, {
            method: 'GET'
        })
            .then(response => response.json())
            .then(response => {
                if (!response.processing) {
                    window.location = response.url
                }
                else {
                    document.querySelector('.js-processing-message').innerHTML = response.message;
                }
            }).catch((error) => {
                console.error('Error:', error);
            });
    }, 2000);
}

const orderProcessingMembership = document.querySelector('.js-order-processing-membership');

if (orderProcessingMembership) {
    setInterval(() => {
        fetch(`/Umbraco/Surface/OrderStatus/GetMembership/?id=${orderProcessingMembership.dataset.id}`, {
            method: 'GET'
        })
            .then(response => response.json())
            .then(response => {
                if (!response.processing) {
                    window.location = response.url
                }
                else {
                    document.querySelector('.js-processing-message').innerHTML = response.message;
                }
            }).catch((error) => {
                console.error('Error:', error);
            });
    }, 2000);
}

const membershipTypeForm = document.getElementById('membership-type-form');

if (membershipTypeForm) {
    const membershipPricePlaceholder = membershipTypeForm.querySelector('.js-membership-price');
    const button = membershipTypeForm.querySelector('button');
    const schemeInput = membershipTypeForm.querySelector('#SchemeId');
    const bandInput = membershipTypeForm.querySelector('#BandId');

    if (membershipPricePlaceholder && button && schemeInput && bandInput) {
        membershipTypeForm.onchange = async () => {
            let response = await fetch('/Umbraco/Surface/MembershipType/Find', {
                method: 'POST',
                body: new FormData(membershipTypeForm)
            });

            let result = await response.json();

            if (result.found) {
                button.disabled = false;
                membershipPricePlaceholder.innerHTML = result.label;
                schemeInput.value = result.schemeId;
                bandInput.value = result.bandId;
            }
            else {
                button.disabled = true;
                membershipPricePlaceholder.innerHTML = '';
                schemeInput.value = '';
                bandInput.value = '';
            }
        };
    }
}